export enum CommentLengthEnum {
  SHORT = "short",
  MEDIUM = "medium",
  LONG = "long",
}

export enum EngagementThresholdEnum {
  STRICT = "strict",
  MODERATE = "moderate",
  DISABLED = "disabled",
}
