import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

export function convertToUTCHrMin(
  hours: number,
  minutes: number,
  period: "AM" | "PM"
) {
  let adjustedHours = hours % 12;
  if (period === "PM") {
    adjustedHours += 12;
  }

  const localTimezone = dayjs.tz.guess();

  const localTime = dayjs.tz(
    `1970-01-01T${adjustedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`,
    localTimezone
  );

  const utcTime = localTime.utc();

  return {
    hours: Number(utcTime.format("HH")),
    minutes: Number(utcTime.format("mm")),
  };
}

export function convertFromUTCHrMin(utcHours: number, utcMinutes: number) {
  const localTimezone = dayjs.tz.guess();

  const utcTime = dayjs.utc(
    `1970-01-01T${utcHours.toString().padStart(2, "0")}:${utcMinutes
      .toString()
      .padStart(2, "0")}:00`
  );

  const localTime = utcTime.tz(localTimezone);

  const hours24 = localTime.hour();
  const minutes = localTime.format("mm");
  const period = hours24 >= 12 ? "PM" : "AM";

  const hours12 = hours24 % 12 || 12;

  return {
    hours: hours12,
    minutes: Number(minutes),
    period: period as "AM" | "PM",
  };
}
