import { Session } from "@supabase/supabase-js";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { AuthEnum } from "../enum/auth.enum";
import { SupabaseInstance } from "../services/supabase.service";
import { useAuthStore } from "../store/auth.store";

function useAuth() {
  const supabase = SupabaseInstance.getSupabase();
  const setSession = useAuthStore((state) => state.setSession);

  const getSessionDataForCookie = (session: Session | null) => {
    if (session) {
      const { access_token, user, refresh_token, expires_in, expires_at } =
        session;
      const { user_metadata, id, email } = user;
      return JSON.stringify({
        access_token,
        user: { user_metadata, id, email },
        refresh_token,
        expires_in,
        expires_at,
      });
    }
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      const { session } = data;
      if (session) {
        Cookies.set(AuthEnum.AUTH_COOKIE_KEY, getSessionDataForCookie(session));
        setSession(session);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      Cookies.set(AuthEnum.AUTH_COOKIE_KEY, getSessionDataForCookie(session));
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);
}

export default useAuth;
