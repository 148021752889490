import axios from "axios";
import { getAuthToken, signOut } from "views/auth/utils/auth.util";
import { envConfig } from "../config/env.config";

export const axiosInstance = axios.create({
  baseURL: envConfig.apiUrl,
});

export const axiosInstanceWithoutToken = axios.create({
  baseURL: envConfig.apiUrl,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (request) => {
    if (request.headers) {
      request.headers.Authorization = `Bearer ${getAuthToken()}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const statusCode = error.response.status;
      const statusText =
        error.response.data?.error?.message ||
        error.response.statusText ||
        error.message ||
        "Something went wrong";

      if (statusCode === 401 || statusCode === 403) {
        signOut();
      }

      return Promise.reject({
        code: statusCode,
        message: statusText,
      });
    }

    return Promise.reject({
      code: error.code || "UNKNOWN",
      message: error.message || "An unknown error occurred",
    });
  }
);
